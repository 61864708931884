import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import PlatformUseCases from "../components//general/single-platform-cpt/PlatformUseCases"
import BreadcrumbSection from '../components/breadcrumb'
import { getSlug, replaceStr } from '../helper'
import { PLATFORM_SHORTCODE } from '../constants'
import PopupSchedule from '../components/popupSchedule/PopupSchedule';

const CptPlatformUseCaseTemplate = ({ data, location }) => {
    const { tabs, mainElements, related } = data.wpCptPlatform.cfPlatform
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = data.wpCptPlatform.cfPlatform.tabs.usecasesSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = data.wpCptPlatform.cfPlatform.tabs.usecasesDefaultSeoFields
    const platformSlug = getSlug(data.wpCptPlatform.uri)
    const platformCptSlug = `${platformSlug}/${mainElements.slug}`
    const breadcrumbItems = [
        {
            name: 'Platforms',
            url: getSlug(data.wpCptPlatform.uri),
        },
        {
            name: mainElements.breadcrumbValue,
            url: platformCptSlug,
        },
        {
            name: `Use Cases`
        }
    ]

    const platformName = [mainElements.platformNamePlural, mainElements.platformNameSingular]

    if (mainElements.hideTabs && mainElements.hideTabs.includes('Use Cases')) {
        if (typeof window !== "undefined") {
            window.location.href = `/${platformCptSlug}`
        }
    }

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={replaceStr(
                    PLATFORM_SHORTCODE,
                    platformName,
                    titleTag
                )}
                description={replaceStr(
                    PLATFORM_SHORTCODE,
                    platformName,
                    metaDescription
                )}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="platform-usecase"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <PlatformUseCases tabs={tabs} mainElements={mainElements} related={related} wpCptPlatform={data.wpCptPlatform} />
        </Layout>
    )
}

export default CptPlatformUseCaseTemplate

export const pageQuery = graphql`
    query PageUseCasePlatformBySlug($slugFlatform: String! , $slugTab: String!) {
        wpCptPlatform(cfPlatform: {mainElements: {slug: {eq: $slugFlatform}}, tabs: {usecasesSlug: {eq: $slugTab}}}) {
            cfPlatform {
                mainElements {
                    useImageInsteadOfSvg
                    breadcrumbValue
                    comingSoonMessage
                    nameOfEmailList
                    fieldGroupName
                    hideTabs
                    logoFileSvg
                    nameOfEmailList
                    platformExcerpt
                    platformNamePlural
                    platformNameSingular
                    slug
                    status
                    createAccountLiveStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    createAccountComingSoonStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    logoFileImage {
                        ...WordpressImageFields
                    }
                    iconFileSvg
                    iconUseImageInsteadOfSvg
                    iconFileImage {
                        ...WordpressImageFields
                    }
                }
                related {
                    relatedDescription
                    relatedH3
                    showRelated
                    relatedPlatforms {
                        ...WordpressCPTPlatformRelatedFields
                    }
                }
                tabs {
                    usecasesH1
                    usecasesH2
                    usecasesH3
                    usecasesIntro
                    helpSlug
                    usecasesSlug
                    datalayersamplesSlug
                    listenersSlug
                    useCaseRows {
                        customizeContent
                        customHeadline
                        customDescription
                        customButtonText
                        selectedUseCase {
                            ...WordpressUseCaseFields
                        }
                    }
                    usecasesDefaultSeoFields {
                        ogDescription
                        ogTitle
                        ogType
                        twitterCard
                        ogImage {
                            ...WordpressImageFields
                        }
                    }
                    usecasesSeoFields {
                        canonicalTagOverride
                        metaDescription
                        robotsDirective
                        titleTag
                    }
                }
            }
            uri
            
        }
    }
`
